import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import './index.css'
import img404 from './OtherImages/page404.jpg'
import myImageUnderC from './OtherImages/underCons.png'

const TermsCondition = React.lazy(() => import('./TermsCondition/index'))
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy/index'))
const CookiePolicy = React.lazy(() => import('./CookiePolicy/index'))


const Footer = React.lazy(() => import('./Homepage/Footer/Footer'))

const Home = React.lazy(() => import('./Homepage2/Home'))
const Header = React.lazy(() => import('./Homepage/Header/Header'))
const SearchResultPage = React.lazy(() => import('./Homepage/Header/SearchResultPage/SearchResultPage'))

// News
const NewsDetailView = React.lazy(() => import('./InnerPages/NewsComponents/NewsDetailView/NewsDetailView'))
const NewsDetailListView = React.lazy(() => import('./InnerPages/NewsComponents/NewsDetailListView/NewsDetailListView'))

// Price New
const PriceHomeNew = React.lazy(() => import('./InnerPages/PriceNewComponent/PriceHome/PriceHome'))
const PriceDetails = React.lazy(() => import('./InnerPages/PriceNewComponent/PriceDetails/PriceDetails'))

// Statistics
const StatisticsHome = React.lazy(() => import('./InnerPages/StatisticsComponent/StatisticsHome/StatisticsHome'))

// MMI
const MarketMoodView = React.lazy(() => import('./InnerPages/MarketMoodComponents/MarketMoodView'))

// Currency
const CurrencyHome = React.lazy(() => import('./InnerPages/CurrencyComponents/CurrencyHome/CurrencyHome'))
const CurrencyDetailPage = React.lazy(() => import('./InnerPages/CurrencyComponents/CurrencyDetail/CurrencyDetailPage'))

// Offer
const HotOfferView = React.lazy(() => import('./InnerPages/HotOfferComponents/OfferView/HotOfferView'))
const OfferDetailView = React.lazy(() => import('./InnerPages/HotOfferComponents/OfferDetailsPage/OfferDetailView'))
const RequirementDetailView = React.lazy(() => import('./InnerPages/HotOfferComponents/RequirementDetailsPage/RequirementDetailView'))

// Deal Karo
const DealKaroView = React.lazy(() => import('./InnerPages/DealKaroComponents/DealKaroView/DealKaroView'))
const DealKaroDetail = React.lazy(() => import('./InnerPages/DealKaroComponents/DealKaroDetail/DealKaroDetail'))

/* Market */
const MarketSellOffer = React.lazy(() => import('./InnerPages/MarketComponents/MarketSellOffer/MarketSellOffer'))
const MarketBuyOffer = React.lazy(() => import('./InnerPages/MarketComponents/MarketBuyOffer/MarketBuyOffer'))
const MarketView = React.lazy(() => import('./InnerPages/MarketComponents/MarketView/MarketView'))
const MarketDetailPage = React.lazy(() => import('./InnerPages/MarketComponents/MarketDetailsPage/MarketDetailPage'))

/*  Profile Imports     */
const ProfileRoot = React.lazy(() => import('./ProfileRoot/ProfileRoot'))

/* Quick Quotation Imports*/
const QuickQuotationView = React.lazy(() => import('./InnerPages/QuickQuationComponent/QuickQuationView/QuickQuationView'))
const QuotationBuy = React.lazy(() => import('./InnerPages/QuickQuationComponent/QuationBuy/QuationBuy'))
const QuotationSell = React.lazy(() => import('./InnerPages/QuickQuationComponent/QuationSell/QuationSell'))

// Forecast Pages
const ForecastDetails = React.lazy(() => import('./InnerPages/ForecastComponents/ForecastDetails/ForecastDetails'))

// Reports
const ReportHome = React.lazy(() => import('./InnerPages/ReportsComponent/ReportHome/ReportHome'))
const ReportDetails = React.lazy(() => import('./InnerPages/ReportsComponent/ReportDetails/ReportDetails'))

// Opinion Pages
const OpinionDetails = React.lazy(() => import('./InnerPages/OpinionComponents/OpinionDetails/OpinionDetails'))

// News 3rd Design
const NewsHomePage3 = React.lazy(() => import('./InnerPages/NewsNewComponents/NewsHome'))

const Wishlist = React.lazy(() => import('./ProfileRoot/Transactions/wishlist/wishlist'))
const NotificationList = React.lazy(() => import('./InnerPages/NotificationList/NotificationList'))
const Login = React.lazy(() => import('./Authantication/Login/Login'))
export default class App extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return <>
            <React.Suspense fallback={() => console.log('... loading ...')}>
                <Router>
                    <Switch>
                        <Route path="/Reports/:id/:id" component={() => <ReportDetails />} />
                        <Route path="/Reports" component={() => <ReportHome />} />
                        <Route path="/Forecast/:id1/:id2" component={() => <ForecastDetails />} />
                        <Route path="/Opinion/:id1/:id2" component={() => <OpinionDetails />} />
                        <Route path="/profile" component={() => <ProfileRoot />} />
                        <Route path="/search" component={() => <SearchResultPage />} />
                        <Route path="/deal-karo/detail" component={() => <DealKaroDetail />} />
                        <Route path="/hot-deals/requirement/details" component={() => <RequirementDetailView />} />
                        <Route path="/hot-deals/offer/details" component={() => <OfferDetailView />} />
                        <Route path="/hot-deals/offer" component={() => <HotOfferView />} />
                        <Route path="/hot-deals/requirement" component={() => <HotOfferView />} />
                        <Route path="/deal-karo" component={() => <DealKaroView />} />
                        <Route path="/Insight/List/Filter/:filterData" component={() => <NewsDetailListView filter={true} />} />
                        <Route path="/Insight/:type1/:tagId/:tagType/:tagName" component={() => <NewsDetailListView />} />
                        <Route path="/Insight/:type1/:id" component={() => <NewsDetailView />} />
                        <Route path="/Insight/news/:type2" component={() => <NewsDetailListView />} />
                        <Route path="/Insight" component={() => <NewsHomePage3 />} />
                        {/* Quick Quotation */}
                        <Route path="/quotation/sell" component={() => <QuotationSell />} />
                        <Route path="/quotation/buy" component={() => <QuotationBuy />} />
                        <Route path="/quotation" component={() => <QuickQuotationView />} />
                        {/* Market Component */}
                        <Route path="/market/buy/estimate" component={() => <MarketBuyOffer />} />
                        <Route path="/market/sell/offer" component={() => <MarketSellOffer />} />
                        <Route path="/market/detail/:type" component={() => <MarketDetailPage />} />
                        <Route path="/market/:type" component={() => <MarketView />} />

                        <Route path="/Price/Detail" component={() => <PriceDetails />} />
                        <Route path="/Price" component={() => <PriceHomeNew />} />

                        {/*     Statistics      */}
                        <Route path="/Statistics" component={() => <StatisticsHome />} />

                        <Route path="/currency-home" component={() => <CurrencyHome />} />
                        <Route path="/marketmood-home" component={() => <MarketMoodView />} />
                        <Route path="/currency-detail/:id" component={() => <CurrencyDetailPage />} />
                        <Route path="/currency-detail/:id/:viewType" component={() => <CurrencyDetailPage />} />

                        <Route path="/underC" component={() => <ClickAfterPage />} />
                        <Route exact path="/" component={() => <Home />} />

                        <Route path="/termscondition" component={() => <TermsCondition />} />
                        <Route path="/privacypolicy" component={() => <PrivacyPolicy />} />
                        <Route path="/cookiepolicy" component={() => <CookiePolicy />} />
                        <Route path="/wishlist" component={() => <Wishlist />} />
                        <Route path="/notificationlist" component={() => <NotificationList />} />
                        <Route path="/login" component={() => <Login />} />
                        <Route component={NotFound404} />
                        
                        
                    </Switch>
                </Router>
            </React.Suspense >
        </>
    }
}

function NotFound404() {

    return <>
        <Header />
        <div className='page_404'>
            <img src={img404}  alt=""/>
        </div>
        <Footer />
    </>
}

class ClickAfterPage extends React.Component {
    render() {
        return <>
            <Header />
            <div className='maincontents'>
            <div className='vspacer2' />
            <div className='vspacer2' />
            <div className='MainDataContainer fixWithFlex alignCenter gap2' >
                <img src={myImageUnderC} alt="" />
            </div>
            <div className='vspacer2' />
            <div className='vspacer2' />    
            </div>
<Footer/>
        </>
    }
}